import * as React from "react";
import { router } from "router";
import { RouterProvider } from "react-router-dom";
import Progress from "components/spinner/Progress";

export default function Router() {
  const [isOfficeInitialized, setIsOfficeInitialized] = React.useState(false);

  Office.onReady().then(() => {
    setIsOfficeInitialized(true);
  });

  return (
    <>
      {Boolean(!isOfficeInitialized) ? (
        <Progress message='Loading app...' />
      ) : (
        <RouterProvider router={router} />
      )}
    </>
  );
}
