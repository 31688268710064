import { MessageBar, MessageBarType } from "@fluentui/react";

export interface NotificationProps {
  type: MessageBarType;
  message: string;
}

function Notification({ type, message }: NotificationProps) {
  return (
    <div
      style={{
        position: "sticky",
        zIndex: 1000,
        width: "100%",
        top: 0,
        boxShadow: "0 5px 10px 0 grey",
      }}>
      <MessageBar messageBarType={type}>{message}</MessageBar>
    </div>
  );
}

export default Notification;
