import { translations } from "./translations";

export const localeMapper = {
  "es-ES": "spanish",
  "es-MX": "spanish",
  "es-AR": "spanish",
  "en-US": "english",
  "en-GB": "english",
  "en-AU": "english",
  "en-CA": "english",
};

export function mapLocaleToTranslation(locale: string | undefined = undefined) {
  const language =
    localeMapper[locale as keyof typeof localeMapper] || "english";
  return language;
}

export function handleTranslation(key: string, locale: string): string {
  const language = mapLocaleToTranslation(locale);
  const translationLang = translations[language as keyof typeof translations];
  const traslatedWord = translationLang[key as keyof typeof translationLang];
  return traslatedWord;
}
