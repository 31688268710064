import { ICheckboxStyles, IStackStyles, IStackTokens } from "@fluentui/react";

const stackTokens: IStackTokens = { childrenGap: 6 };
const globalStackTokens: IStackTokens = { childrenGap: 10 };
const stackStyles: Partial<IStackStyles> = {
  root: { padding: "1rem" },
};
const stackItemsStyles: Partial<IStackStyles> = {
  root: { marginBottom: "1rem" },
};
const labelStyle: ICheckboxStyles = {
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
};
const backgroundStackStyles: Partial<IStackStyles> = {
  root: { padding: "1rem", backgroundColor: "#fafafa" },
};

export {
  stackTokens,
  globalStackTokens,
  stackStyles,
  stackItemsStyles,
  labelStyle,
  backgroundStackStyles,
};
